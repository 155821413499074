<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.type" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">正常出库</el-radio-button>
      <el-radio-button :label="2">环保处理</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <!-- <el-form-item label="下单分类" prop="cate_id">
          <el-select v-model="table.params.cate_id" filterable  placeholder="请选择" clearable @focus="getOrderCate" @change="onSearch">
            <el-option
              v-for="item in orderCateOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="商品名称" prop="goods_name">
          <el-select
            v-model="table.params.goods_name"
            filterable
            remote
            placeholder="请输入"
            :remote-method="remoteMethod"
            :loading="selLoading"
            clearable
            @change="onSearch" value-key="id">
              <el-option v-for="item in goodsArr" :label="item.good_name" :value="item.good_name" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="出库日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <template v-if="table.params.type == 1">
          <el-form-item label="客户名称" prop="merchant_id">
            <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="changeMerchant">
              <el-option
                v-for="item in merchantArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="食堂名称" prop="address_id">
            <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
              <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset2">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" plain @click="exportData('/admin/inventory/exportDetails')" icon="el-icon-download" >全部导出</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="plexportData('/admin/inventory/exportDetails')">批量导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="table.params.type == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
       <!-- 规格 -->
      <template v-slot:spec_name="{row}">
        <span v-if="row.spec_name.lengt > 1">存在多个商品规格</span>
        <span v-else>{{row.spec_name[0]}}</span>
      </template>
      <template v-slot:quantity="{row}">
        <span>{{row.quantity}}{{row.unit_name}}</span>
        <span v-if="row.refund_quantity > 0" style="margin-left:15px; color:#f56c6c;">(退货{{row.refund_quantity}}{{row.unit_name}})</span>
      </template>
      <!-- 操作单位转换产生的出库的数据，食堂名称请显示为“转换单位” -->
      <template v-slot:store_name="{row}">
        <span v-if="!!row.store_name">{{row.store_name}}</span>
        <span v-if="!!row.chane_unit">转换单位</span>
      </template>
      <template v-slot:cate_name="{row}">
        <span>{{row.cate_name}}</span>
        <span v-if="row.order_name_tow"> / {{row.order_name_tow}}</span>
      </template>
      <template v-slot:voucher="{row}">
        <el-image
          v-if="!!row.voucher"
          style="width: 40px; height: 40px"
          :src="row.voucher"
          fit="cover"
          :preview-src-list="[row.voucher]"></el-image>
        <span v-else></span>
      </template>
      
    </VTable>

    <el-dialog
      append-to-body
      width="400px"
      title="绑定批次号"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      >
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='100px'
          label-position='right'
          :model='form.data'
          :rules='form.rules'
          :size="subUnitSize"
        >
          <el-form-item label="所属批次" prop="batch_id">
            <el-select v-model="form.data.batch_id" filterable placeholder="请选择">
              <el-option
                v-for="item in batchArr"
                :key="item.id"
                :label="item.inventory_no"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      <template #footer>
        <el-row type="flex" justify="end">
          <el-button @click='dialogVisible = false'>取消</el-button>
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
        </el-row>
      </template>
    </el-dialog>
    

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      field: [
        { name: "id", label: "ID", width: "80", hidden: false },
        { name: "cate_name", label: "下单分类", hidden: false},
        { name: "norm_name", label: "商品名称", showTooltip: true, hidden: false},
        { name: "quantity", label: "出库数量(单位)",width:'180', hidden: false},
        { name: "spec_name", label: "规格",  hidden: false},
        { name: "store_name", label: "食堂名称", hidden: false},
        { name: "create_time", label: "操作时间", hidden: false},
        { name: "account_name", label: "操作人", hidden: false},
      ],
      field2: [
        { name: "id", label: "ID", width: "80", hidden: false },
        { name: "cate_name", label: "下单分类", hidden: false},
        { name: "norm_name", label: "商品名称", showTooltip: true, hidden: false},
        { name: "quantity", label: "出库数量(单位)",width:'180', hidden: false},
        { name: "spec_name", label: "规格",  hidden: false},
        { name: "voucher", label: "报损凭证",  hidden: false},
        { name: "create_time", label: "操作时间", hidden: false},
        { name: "account_name", label: "操作人", hidden: false},
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          type: 1, // 1:正常出库 2:环保处理
          cate_id: '',
          order_cate_arr: [],
          order_cate_id: '', // 下单分类
          order_cate_tow: '', // 二级下单分类
          goods_name: '',
          area: [],
          province: '',
          city: '',
          date:[],
          start_time: '',
          end_time: '',
          merchant_id: '',
          address_id: '',
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
      },
      selLoading: false,
      goodsArr: [],
      batchArr: [],// 批次号

      dialogVisible: false,
      form: {
        loading: false,
        data: {
          id: '', //出库记录ID
          batch_id: '', // 批次号
        },
        rules:{
          batch_id: [{ required: true, message:'请选择批次号', trigger: 'change'}],
        }
      },
      orderCateArr: [],
      merchantArr: [],
      storeArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _area = this.table.params.area;
      if(_area.length == 1) {
        _params.province = _area[0]
      } else if(_area.length == 2) {
        _params.province = _area[0]
        _params.city = _area[1]
      } else {
        _params.province = '';
        _params.city = '';
      }
      let _date = this.table.params.date;
      if(!!_date && _date.length > 0) {
        _params.start_time = !!_date ? _date[0] : '';
        _params.end_time = !!_date ? _date[1] : '';
      } 
      _params.cate_id = this.table.params.order_cate_id;
      delete _params.order_cate_id;
      delete _params.order_cate_arr;
      delete _params.date
      delete _params.area;
      this.table.loading = true;
      this.$http.get('/admin/inventory/outList', {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 20;
      this.table.params.order_cate_arr = [];
      this.table.params.order_cate_id = '';
      this.table.params.order_cate_tow = '';
      this.getTable();
    },
    // 查询商品
    remoteMethod(query) {
      if(query !== '') {
        this.selLoading = true;
        this.$http.post("/admin/goods/list", {page:1,count:10000,good_name: query}).then((res) => {
          if(res.code == 1) {
            this.goodsArr = res.data.list;
          }
        }).finally(() => {
          this.selLoading = false;
        })
      } else {
        this.goodArr = [];
      }
    },
    // 绑定批次
    handleNo(row) {
      this.dialogVisible = true;
      this.form.data.id = row.id; //出库记录ID
      // 获取批次号
      this.$http.get("/admin/inventory/getBatch", {params:{page:1,count:10000,spec_id:row.spec_id,stash_id:row.stash_id}}).then((res) => {
        if(res.code == 1) {
          this.batchArr = res.data.list;
        }
      })
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if (valid) {
          let _param = {};
          _param = {
            id: this.form.data.id,
            batch_id: this.form.data.batch_id,
          }
          this.form.loading = true;
          this.$http.post('/admin/inventory/bind', _param).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！')
              this.toggle(false);
              this.$emit('refresh')
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    },
  }
}
</script>
